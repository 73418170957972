@font-face {
    font-family: 'Avenir LT Std';
    src: url('../assets/fonts/AvenirLTStd-Light.eot');
    src: url('../assets/fonts/AvenirLTStd-Light.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/AvenirLTStd-Light.woff2') format('woff2'),
    url('../assets/fonts/AvenirLTStd-Light.woff') format('woff'),
    url('../assets/fonts/AvenirLTStd-Light.ttf') format('truetype'),
    url('../assets/fonts/AvenirLTStd-Light.svg#../assets/fonts/AvenirLTStd-Light') format('svg');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir LT Std';
    src: url('../assets/fonts/AvenirLTStd-LightOblique.eot');
    src: url('../assets/fonts/AvenirLTStd-LightOblique.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/AvenirLTStd-LightOblique.woff2') format('woff2'),
    url('../assets/fonts/AvenirLTStd-LightOblique.woff') format('woff'),
    url('../assets/fonts/AvenirLTStd-LightOblique.ttf') format('truetype'),
    url('../assets/fonts/AvenirLTStd-LightOblique.svg#../assets/fonts/AvenirLTStd-LightOblique') format('svg');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Avenir LT Std';
    src: url('../assets/fonts/AvenirLTStd-Book.eot');
    src: url('../assets/fonts/AvenirLTStd-Book.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/AvenirLTStd-Book.woff2') format('woff2'),
    url('../assets/fonts/AvenirLTStd-Book.woff') format('woff'),
    url('../assets/fonts/AvenirLTStd-Book.ttf') format('truetype'),
    url('../assets/fonts/AvenirLTStd-Book.svg#../assets/fonts/AvenirLTStd-Book') format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir LT Std';
    src: url('../assets/fonts/AvenirLTStd-BookOblique.eot');
    src: url('../assets/fonts/AvenirLTStd-BookOblique.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/AvenirLTStd-BookOblique.woff2') format('woff2'),
    url('../assets/fonts/AvenirLTStd-BookOblique.woff') format('woff'),
    url('../assets/fonts/AvenirLTStd-BookOblique.ttf') format('truetype'),
    url('../assets/fonts/AvenirLTStd-BookOblique.svg#../assets/fonts/AvenirLTStd-BookOblique') format('svg');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Avenir LT Std';
    src: url('../assets/fonts/AvenirLTStd-Roman.eot');
    src: url('../assets/fonts/AvenirLTStd-Roman.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/AvenirLTStd-Roman.woff2') format('woff2'),
    url('../assets/fonts/AvenirLTStd-Roman.woff') format('woff'),
    url('../assets/fonts/AvenirLTStd-Roman.ttf') format('truetype'),
    url('../assets/fonts/AvenirLTStd-Roman.svg#../assets/fonts/AvenirLTStd-Roman') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir LT Std';
    src: url('../assets/fonts/AvenirLTStd-Oblique.eot');
    src: url('../assets/fonts/AvenirLTStd-Oblique.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/AvenirLTStd-Oblique.woff2') format('woff2'),
    url('../assets/fonts/AvenirLTStd-Oblique.woff') format('woff'),
    url('../assets/fonts/AvenirLTStd-Oblique.ttf') format('truetype'),
    url('../assets/fonts/AvenirLTStd-Oblique.svg#../assets/fonts/AvenirLTStd-Oblique') format('svg');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Avenir LT Std';
    src: url('../assets/fonts/AvenirLTStd-Medium.eot');
    src: url('../assets/fonts/AvenirLTStd-Medium.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/AvenirLTStd-Medium.woff2') format('woff2'),
    url('../assets/fonts/AvenirLTStd-Medium.woff') format('woff'),
    url('../assets/fonts/AvenirLTStd-Medium.ttf') format('truetype'),
    url('../assets/fonts/AvenirLTStd-Medium.svg#../assets/fonts/AvenirLTStd-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir LT Std';
    src: url('../assets/fonts/AvenirLTStd-MediumOblique.eot');
    src: url('../assets/fonts/AvenirLTStd-MediumOblique.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/AvenirLTStd-MediumOblique.woff2') format('woff2'),
    url('../assets/fonts/AvenirLTStd-MediumOblique.woff') format('woff'),
    url('../assets/fonts/AvenirLTStd-MediumOblique.ttf') format('truetype'),
    url('../assets/fonts/AvenirLTStd-MediumOblique.svg#../assets/fonts/AvenirLTStd-MediumOblique') format('svg');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Avenir LT Std';
    src: url('../assets/fonts/AvenirLTStd-Heavy.eot');
    src: url('../assets/fonts/AvenirLTStd-Heavy.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/AvenirLTStd-Heavy.woff2') format('woff2'),
    url('../assets/fonts/AvenirLTStd-Heavy.woff') format('woff'),
    url('../assets/fonts/AvenirLTStd-Heavy.ttf') format('truetype'),
    url('../assets/fonts/AvenirLTStd-Heavy.svg#../assets/fonts/AvenirLTStd-Heavy') format('svg');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir LT Std';
    src: url('../assets/fonts/AvenirLTStd-HeavyOblique.eot');
    src: url('../assets/fonts/AvenirLTStd-HeavyOblique.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/AvenirLTStd-HeavyOblique.woff2') format('woff2'),
    url('../assets/fonts/AvenirLTStd-HeavyOblique.woff') format('woff'),
    url('../assets/fonts/AvenirLTStd-HeavyOblique.ttf') format('truetype'),
    url('../assets/fonts/AvenirLTStd-HeavyOblique.svg#../assets/fonts/AvenirLTStd-HeavyOblique') format('svg');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Avenir LT Std';
    src: url('../assets/fonts/AvenirLTStd-Black.eot');
    src: url('../assets/fonts/AvenirLTStd-Black.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/AvenirLTStd-Black.woff2') format('woff2'),
    url('../assets/fonts/AvenirLTStd-Black.woff') format('woff'),
    url('../assets/fonts/AvenirLTStd-Black.ttf') format('truetype'),
    url('../assets/fonts/AvenirLTStd-Black.svg#../assets/fonts/AvenirLTStd-Black') format('svg');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir LT Std';
    src: url('../assets/fonts/AvenirLTStd-BlackOblique.eot');
    src: url('../assets/fonts/AvenirLTStd-BlackOblique.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/AvenirLTStd-BlackOblique.woff2') format('woff2'),
    url('../assets/fonts/AvenirLTStd-BlackOblique.woff') format('woff'),
    url('../assets/fonts/AvenirLTStd-BlackOblique.ttf') format('truetype'),
    url('../assets/fonts/AvenirLTStd-BlackOblique.svg#../assets/fonts/AvenirLTStd-BlackOblique') format('svg');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Avenir LT Std';
    src: url('../assets/fonts/AvenirLTStd-Black.eot');
    src: url('../assets/fonts/AvenirLTStd-Black.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/AvenirLTStd-Black.woff2') format('woff2'),
    url('../assets/fonts/AvenirLTStd-Black.woff') format('woff'),
    url('../assets/fonts/AvenirLTStd-Black.ttf') format('truetype'),
    url('../assets/fonts/AvenirLTStd-Black.svg#../assets/fonts/AvenirLTStd-Black') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir LT Std';
    src: url('../assets/fonts/AvenirLTStd-BlackOblique.eot');
    src: url('../assets/fonts/AvenirLTStd-BlackOblique.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/AvenirLTStd-BlackOblique.woff2') format('woff2'),
    url('../assets/fonts/AvenirLTStd-BlackOblique.woff') format('woff'),
    url('../assets/fonts/AvenirLTStd-BlackOblique.ttf') format('truetype'),
    url('../assets/fonts/AvenirLTStd-BlackOblique.svg#../assets/fonts/AvenirLTStd-BlackOblique') format('svg');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: "Wingdings Regular";
    src: url('../assets/fonts/Wingdings-Regular.ttf');
}

// FIX POUR IE
@font-face {
    font-family: 'mymemoc';
    src: url('../assets/fonts/mymemoc.eot');
    src: url('../assets/fonts/mymemoc.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/mymemoc.woff') format('woff'),
    url('../assets/fonts/mymemoc.ttf') format('truetype'),
    url('../assets/fonts/mymemoc.svg#mymemoc') format('svg');
    font-weight: normal;
    font-style: normal;
}
