p-autocomplete {
    width: 100%;

    &:focus-visible {
        outline: 0;
    }

    &.hasValue {
        .p-autocomplete {
            &:after {
                display: none;
            }
        }
    }

    .p-autocomplete {
        padding: var(--space-xs);
        color: var(--ion-color-primary);
        width: 100%;
        cursor: pointer;
        display: inline-block !important;

        &:after {
            content: '';
            position: absolute;
            right: 2.2rem;
            top: 2.4rem;
            background-image: url(../assets/icon/search.svg);
            width: 1.9rem;
            height: 1.9rem;
            background-repeat: no-repeat;
            background-size: 1.8rem;
        }

        .p-autocomplete-input {
            width: 100%;
            z-index: 10001;
            height: 4.7rem;
            border-radius: 2.2rem;
            border: none;
            padding: 0 2.5rem;
            font-size: 1.6rem;
            background-color: white;
            display: flex;

            &:focus-visible {
                outline: 0;
            }
        }

        .p-autocomplete-panel {
            background-color: white;
            padding: 1.5rem;
            margin-top: 2rem;

            .p-autocomplete-items {
                padding: 0.5rem 0.5rem 1.5rem 0.5rem;

                .p-autocomplete-item {
                    font-size: 1.6rem;
                    padding: 1.5rem 0.5rem;
                }
                .p-autocomplete-item:hover {
                    background-color: var(--ion-color-primary);
                    color: white;
                }
            }

            .p-state-highlight {
                background-color: var(--ion-color-primary);
                color: white;
            }
        }
    }
}
