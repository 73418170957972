// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/


:root {

    --ion-color-primary: #0088ce;
    --ion-color-primary-rgb: 0, 136, 206;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #0078b5;
    --ion-color-primary-tint: #1a94d3;

    --ion-color-secondary: #4d4f53;
    --ion-color-secondary-rgb: 77, 79, 83;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #444649;
    --ion-color-secondary-tint: #5f6164;

    --ion-color-dark: #262525;
    --ion-color-dark-rgb: 38, 37, 37;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #212121;
    --ion-color-dark-tint: #3c3b3b;

    --ion-color-success: #2dd36f;
    --ion-color-success-rgb: 45, 211, 111;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0, 0, 0;
    --ion-color-success-shade: #28ba62;
    --ion-color-success-tint: #42d77d;

    --ion-color-warning: #ffc409;
    --ion-color-warning-rgb: 255, 196, 9;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0ac08;
    --ion-color-warning-tint: #ffca22;

    --ion-color-danger: #d52b1e;
    --ion-color-danger-rgb: 213, 43, 30;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #bb261a;
    --ion-color-danger-tint: #d94035;

    --ion-color-medium: #92949c;
    --ion-color-medium-rgb: 146, 148, 156;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0, 0, 0;
    --ion-color-medium-shade: #808289;
    --ion-color-medium-tint: #9d9fa6;

    --ion-color-light: #ffffff;
    --ion-color-light-rgb: 255, 255, 255;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #e0e0e0;
    --ion-color-light-tint: #ffffff;

    /** CUSTOM COLORS (SNCF CODE) **/
    --ion-color-violet: #6e1e78;
    --ion-color-violet-rgb: 110, 30, 120;
    --ion-color-violet-contrast: #ffffff;
    --ion-color-violet-contrast-rgb: 255, 255, 255;
    --ion-color-violet-shade: #611a6a;
    --ion-color-violet-tint: #7d3586;

    --ion-color-prune: #a1006b;
    --ion-color-prune-rgb: 161, 0, 107;
    --ion-color-prune-contrast: #ffffff;
    --ion-color-prune-contrast-rgb: 255, 255, 255;
    --ion-color-prune-shade: #8e005e;
    --ion-color-prune-tint: #aa1a7a;

    --ion-color-framboise: #cd0037;
    --ion-color-framboise-rgb: 205, 0, 55;
    --ion-color-framboise-contrast: #ffffff;
    --ion-color-framboise-contrast-rgb: 255, 255, 255;
    --ion-color-framboise-shade: #b40030;
    --ion-color-framboise-tint: #d21a4b;

    --ion-color-orange: #e05206;
    --ion-color-orange-rgb: 224, 82, 6;
    --ion-color-orange-contrast: #ffffff;
    --ion-color-orange-contrast-rgb: 255, 255, 255;
    --ion-color-orange-shade: #c54805;
    --ion-color-orange-tint: #e3631f;

    --ion-color-jaune-safran: #ffb612;
    --ion-color-jaune-safran-rgb: 255, 182, 18;
    --ion-color-jaune-safran-contrast: #000000;
    --ion-color-jaune-safran-contrast-rgb: 0, 0, 0;
    --ion-color-jaune-safran-shade: #e0a010;
    --ion-color-jaune-safran-tint: #ffbd2a;

    --ion-color-vert-anis: #d2e100;
    --ion-color-vert-anis-rgb: 210, 225, 0;
    --ion-color-vert-anis-contrast: #000000;
    --ion-color-vert-anis-contrast-rgb: 0, 0, 0;
    --ion-color-vert-anis-shade: #b9c600;
    --ion-color-vert-anis-tint: #d7e41a;

    --ion-color-vert-pomme: #82be00;
    --ion-color-vert-pomme-rgb: 130, 190, 0;
    --ion-color-vert-pomme-contrast: #000000;
    --ion-color-vert-pomme-contrast-rgb: 0, 0, 0;
    --ion-color-vert-pomme-shade: #72a700;
    --ion-color-vert-pomme-tint: #8fc51a;

    --ion-color-bleu-canard: #009aa6;
    --ion-color-bleu-canard-rgb: 0, 154, 166;
    --ion-color-bleu-canard-contrast: #ffffff;
    --ion-color-bleu-canard-contrast-rgb: 255, 255, 255;
    --ion-color-bleu-canard-shade: #008892;
    --ion-color-bleu-canard-tint: #1aa4af;

    --ion-color-carbone: #333333;
    --ion-color-carbone-rgb: 51, 51, 51;
    --ion-color-carbone-contrast: #ffffff;
    --ion-color-carbone-contrast-rgb: 255, 255, 255;
    --ion-color-carbone-shade: #2d2d2d;
    --ion-color-carbone-tint: #474747;

    --ion-color-cool-gray-11: #4d4f53;
    --ion-color-cool-gray-9: #747678;
    --ion-color-cool-gray-7: #a0a0a0;
    --ion-color-cool-gray-5: #b9b9b9;

    --ion-color-cool-gray-5-rgb: 185, 185, 185;
    --ion-color-cool-gray-5-contrast: #000000;
    --ion-color-cool-gray-5-contrast-rgb: 0, 0, 0;
    --ion-color-cool-gray-5-shade: #a3a3a3;
    --ion-color-cool-gray-5-tint: #c0c0c0;

    --ion-color-cool-gray-3: #d7d7d7;
    --ion-color-cool-gray-1: #f2f2f2;
    --ion-color-gray-40: #bcbcbc;

    --ion-text-color: var(--ion-color-cool-gray-11);

    /** FONTS **/
    --ion-font-family: 'Avenir LT Std', 'Roboto', sans-serif;

    --font-size-xxs: 1rem;
    --font-size-xs: 1.2rem;
    --font-size-s: 1.4rem;
    --font-size-m: 1.6rem;
    --font-size-l: 1.8rem;
    --font-size-xl: 2rem;
    //--font-size-xxl: 2.8rem;

    /** SPACES **/
    --space-xxs: 0.8rem;
    --space-xs: 1rem;
    --space-s: 1.2rem;
    --space-m: 1.6rem;
    --space-xl: 2rem;

    --ion-padding: 1rem;
    --ion-margin: calc(var(--ion-padding) * 2);

    /** BORDERS **/
    --default-box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.05);
    --default-border-radius: 0.4rem;
    --default-border-color: var(--ion-color-cool-gray-7);
    --default-border-width: 0.5px;
    --default-box-shadow-previsu: 0 0 20px -7px rgba(0, 0, 0, 0.75);

    /** INPUT **/
    --default-placeholder-color: var(--ion-color-cool-gray-11);
    --default-placeholder-opacity: 0.6;

    --default-duration: 0.3s;

    --search-block-height: 6.7rem;

    --tab-header-height: 3rem;

    --left-icon-top-position: 1.1rem;


}

.ion-color-cool-gray-5 {
    --ion-color-base: var(--ion-color-cool-gray-5);
    --ion-color-base-rgb: var(--ion-color-cool-gray-5-rgb);
    --ion-color-contrast: var(--ion-color-cool-gray-5-contrast);
    --ion-color-contrast-rgb: var(--ion-color-cool-gray-5-contrast-rgb);
    --ion-color-shade: var(--ion-color-cool-gray-5-shade);
    --ion-color-tint: var(--ion-color-cool-gray-5-tint);
}

/** ALERTS **/
.sc-ion-alert-md-h{
    --min-width:368px;

}
.alert-radio-label.sc-ion-alert-md {
    font-size: 14px;
}
