/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '@ionic/angular/css/normalize.css';
@import '@ionic/angular/css/structure.css';
@import '@ionic/angular/css/typography.css';
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '@ionic/angular/css/padding.css';
@import '@ionic/angular/css/float-elements.css';
@import '@ionic/angular/css/text-alignment.css';
@import '@ionic/angular/css/text-transformation.css';
@import '@ionic/angular/css/flex-utils.css';

@import 'swiper/scss';
@import 'swiper/scss/autoplay';
@import 'swiper/scss/keyboard';
@import 'swiper/scss/pagination';
@import 'swiper/scss/scrollbar';
@import 'swiper/scss/zoom';

@import '@ng-select/ng-select/themes/default.theme.css';

@import 'theme/fonts';
@import 'theme/ionic';
@import 'theme/primeng';

// https://www.aleksandrhovhannisyan.com/blog/respecting-font-size-preferences-rems-62-5-percent/
html {
    font-size: 62.5%; // 1rem = 10px
}

body {
    font-size: var(--default-font-size, 1.6rem); // Si première valeur non définie, fallback sur 1.6em (16px).
    user-select: none;
    -webkit-touch-callout: none;
    background: var(--ion-color-cool-gray-7);

    &.mobile {
        > app-root {
            ion-app.ion-page ion-router-outlet {
                width: 360px;
            }
        }
    }

    // PDF GENERATION
    // ---------------------------
    &.previsu-pdf {
        // Reset body so it behaves normally
        overflow: scroll !important;
        position: relative !important;
        background-color: white !important;

        // Remove Ionic layout
        ion-app,
        ion-nav,
        ion-tab,
        ion-tabs,
        app-root,
        ion-router-outlet,
        .ion-page,
        ion-content {
            --background: white;
            contain: unset !important;
            position: relative !important;
            overflow: visible !important;
            box-shadow: none;
        }

        // Hide environment corner ribbon
        app-bandeau-env {
            visibility: hidden;
        }
    }
}

app-root {
    @media screen and (min-width: 1024px) {
        background-color: var(--ion-color-cool-gray-7) !important;
    }

    ion-app.ion-page ion-router-outlet {
        overflow: hidden;
        transition: width 0.3s ease-in-out, left 0.3s ease-in-out;

        @media screen and (min-width: 1024px) {
            width: 1024px;
            box-shadow: var(--default-box-shadow-previsu);
        }
    }
}

html,
p {
    margin: 0;
}

.content {
    padding: 0 var(--space-xl);
}

.no-select {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Chrome/Safari/Opera */
    -khtml-user-select: none;
    /* Konqueror */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE/Edge */
    user-select: none;
    /* non-prefixed version, currently not supported by any browser */
}

.empty-state {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    font-size: var(--font-size-m);
    line-height: 2.4rem;
    font-weight: 500;
    font-style: italic;
    color: var(--ion-color-primary);

    span {
        width: 240px;
        text-align: center;
    }
}

@media (pointer: fine) {
    @media all and (max-width: 1024px) {
        ::-webkit-scrollbar {
            width: 12px !important;
            -webkit-appearance: none !important;
        }
    }

    ::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.5) !important;
        border-radius: 10px !important;
        border: 2px solid #ffffff !important;
    }

    ::-webkit-scrollbar-track {
        border-radius: 10px !important;
        background-color: #ffffff !important;
    }

    * {
        scrollbar-width: thin;
    }
}

pdf-viewer {
    height: 100%;
}

.centered-alert {
    .alert-header{
        text-align: center;
        width: 100%;
        height: 100px;
        font-size: 18px;
    }
    .alert-message{
        display: inline-flex;
        text-align: center;
        width: 100%;
        font-size: 16px;
        padding-top: 15px !important;
        padding-bottom: 15px !important;
        padding-left: 15px !important;
        padding-right: 15px !important;
        border-top: 1px solid #cabdbd;
    }
    .alert-button-group {
        text-align: center;
        width: 100%;
        font-size: 18px;
  }
    .alert-button-group {
        display: flex;
        justify-content: space-evenly;
        flex-direction: row;
        align-items: center;
      }

    .equal-sized-buttons .alert-button {
        flex: 1;
        height: 50px;
        font-size: 16px;
      }
    .blue-light-button {
        font-size: 18px;
        color: #ffffff !important;
        background-color: #1491D2 !important;
        border: none;
        border-radius: 5px;
        padding: 10px 50px;
        cursor: pointer;
        }
}

.toggle-container-hide {
        display: none;
    }






