ion-header {
    ion-toolbar {
        ion-title {
            padding: 0;
        }
    }
}

ion-button {
    height: 5rem;
    font-size: var(--font-size-m);
    text-transform: none;
    letter-spacing: normal;
    --box-shadow: none;

    &.transparent {
        --background: transparent;
        --background-hover: none;
        --ripple-color: transparent;
        --border-width: 0;
    }
}

ion-router-outlet {
    max-width: 102.4rem;
    margin: 0 auto;
    box-shadow: 0 0 20px -7px rgba(0, 0, 0, 0.75);

    --side-min-width: 37rem;
    --side-width: 37rem;
}

ion-item {
    --padding-start: 0;
    --inner-padding-end: 0;
    --ripple-color: transparent;
    --background-hover: transparent;
}

ion-toggle {
    --background: var(--ion-color-cool-gray-3);
    --handle-background-checked: var(--ion-color-vert-pomme);
    --background-checked: var(--ion-color-vert-pomme);
}

ion-input {
    *,
    &.sc-ion-input-md-h {
        font-size: var(--font-size-s);
        line-height: normal;
        min-height: 4rem;
        --background: white;
        --padding-start: var(--space-xs);
        --padding-end: var(--space-xs);
        --placeholder-color: var(--default-placeholder-color);
        --placeholder-opacity: var(--default-placeholder-opacity);
        border-style: solid;
        border-radius: var(--default-border-radius);
        border-color: var(--default-border-color);
        border-width: var(--default-border-width);
        color: var(--ion-color-secondary);
        margin-top: 0;
    }

    .native-input[disabled].sc-ion-input-md {
        opacity: var(--input-disabled-opacity);
    }
}

ion-select {
    font-size: var(--font-size-s);
    min-height: 4rem;
    line-height: normal;
    --padding-start: var(--space-xs);
    --padding-end: var(--space-xs);
    --placeholder-color: var(--default-placeholder-color);
    --placeholder-opacity: var(--default-placeholder-opacity);
    border-style: solid;
    border-radius: var(--default-border-radius) !important;
    border-color: var(--default-border-color) !important;
    border-width: var(--default-border-width) !important;
    color: var(--ion-color-dark);
    margin-top: 0;
}

.form-item .label-stacked {
    font-weight: 700;
    font-size: var(--font-size-m);
    text-transform: uppercase;
    color: var(--ion-color-secondary);
    transform: initial;
    margin-bottom: var(--space-xxs);
}

/* Set the icon color and opacity */
ion-select::part(icon) {
    transform: none !important;
}

.select-interface-option {
    --background-hover: var(--ion-color-primary);
    padding: 0 15px;
}
.select-interface-option:first-child {
    --background-hover: var(--ion-color-primary);
    padding-top: 15px;
}

ion-segment {
    position: relative;
    overflow: visible;
    background-color: white;
    box-shadow: 0 var(--space-xxs) var(--space-xxs) 0 rgba(0, 0, 0, 0.1);
    margin-bottom: var(--space-m);

    ion-segment-button {
        --indicator-height: 3px;
        --color: var(--ion-color-cool-gray-5);

        ion-icon {
            font-size: 2.6rem;
        }

        //&.segment-activated {
        //    // Bordure bleue
        //    &::after {
        //        position: absolute;
        //        content: '';
        //        left: 0;
        //        width: 100%;
        //        bottom: 0;
        //        height: 4px;
        //        border-radius: 2px;
        //        background-color: var(--ion-color-primary);
        //    }
        //}

        &:not(.segment-activated) {
            // !important;
        }

        //&.hidden {
        //    pointer-events: none;
        //    visibility: hidden;
        //    width: 0 !important;
        //    margin: 0 !important;
        //    padding: 0;
        //    flex: none !important;
        //}
    }
}

ion-modal {
    &.modal-evaluation-article {
        --height: 310px;
        --width: 100%;
        bottom: 0;
    }
    &.modal-reorder-rubriques {
        --height: 100% !important;
        --width: 100%;
        --max-height: 100%;
    }

    &.not-fullscreen {
        .modal-wrapper {
            top: auto;
            bottom: 0;
            contain: none;
            border-radius: 2px;
            overflow: hidden;
        }

        ion-backdrop {
            visibility: visible;
        }
    }
    &.downloader {
        //TODO: Ajuster la modale pour correspondre au design de la version originelle.
        // --width: 60%;
        // --max-width: 60%;
        // --height: 100%;
    }
}
